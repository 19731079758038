var timeout = null
export const debouncer = (callback, delay) => {
  if (timeout) {
    clearTimeout(timeout)
    timeout = null
  }
  timeout = setTimeout(() => {
    callback()
  }, delay)
}

export const debounce = (callback, delay) => {
  if (timeout) {
    clearTimeout(timeout)
    timeout = null
  }
  timeout = setTimeout(() => {
    callback()
  }, delay)
}

export const getPromoAmount = (promo, price) => {
  let result = 0
  if (promo) {
    if (promo.is_percent == 1)
      result = parseInt(price * promo.amount / 100)
    else
      result = parseInt(promo.amount)
  }
  return result
}

export default { debouncer, getPromoAmount, debounce }